
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { cookieIsSet, setCookie } from '~/utils/cookies';
import type { GlobalCtaFixedUpperBannerBlokInterface } from '~/types/global';

export default defineComponent({
    name: 'GlobalCtaFixedUpperBanner',
    props: {
        blok: {
            type: Object as PropType<GlobalCtaFixedUpperBannerBlokInterface>,
            required: true,
        },
        cookieName: {
            type: String,
            required: false,
            default: 'lfc-fix-up-ban',
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            hidden: false,
            delayHasPassed: false,
            hardHidden: true,
        };
    },
    computed: {
        cShow() {
            return this.show && !this.hidden && this.delayHasPassed;
        },
    },
    watch: {
        cShow(newValue: boolean) {
            if (newValue) {
                this.hardHidden = false;
            } else {
                setTimeout(() => {
                    this.hardHidden = true;
                }, 500); // 0.5 seconds, match to CSS ease-in-out
            }
        },
    },
    mounted() {
        const isPreview = this.$route.query.preview;
        const isInGlobal = this.$route.path.startsWith('/global/');
        const hiddenByCookie = cookieIsSet(this.cookieName);
        if (hiddenByCookie || (isPreview && !isInGlobal)) {
            this.hideBanner();
        }

        // Show after `delaySeconds` seconds
        let delaySeconds = this.blok.delaySeconds || 0;
        if (isInGlobal) {
            delaySeconds = 0;
        }
        setTimeout(() => {
            this.delayHasPassed = true;
        }, delaySeconds * 1000); // in milliseconds
    },
    methods: {
        hideBanner() {
            this.hidden = true;
        },
        dismiss() {
            this.hideBanner();
            setCookie(this.cookieName, '1', 30);
        },
    },
});
