export function setCookie(name: string, value: string, expiresAfterDays: number) {
    let expires: string;
    if (expiresAfterDays) {
        const expDate = new Date();
        expDate.setDate(expDate.getDate() + expiresAfterDays);
        expires = expDate.toUTCString();
    } else {
        expires = 'session';
    }
    document.cookie = `${name}=${value};expires=${expires};path=/`;
}

export function getCookieValue(name: string): string | null {
    const cookie = document.cookie.split(';').find((c) => c.trim().startsWith(name));
    if (cookie) {
        return cookie.trim().split('=')[1];
    }
    return null;
}

export function cookieIsSet(name: string): boolean {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim().split('=')[0]);
    if (cookies.includes(name)) {
        return true;
    }
    return false;
}
